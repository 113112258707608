import * as React from "react";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import { howItWorksFeatured } from "../assets/homepageData";

import SEO from '../components/SEO'

const About = () => {
  const { aboutLocalTier } =
    useStaticQuery(graphql`
      query aboutImage {
       aboutLocalTier: file(relativePath: { eq: "jason-goodman-X8H8vPcelPk-unsplash.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 800
              height: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        } 
      }
    `);
  const aboutLocalTierImageData = getImage(aboutLocalTier)

  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"About LocalTier"}
        description={"our Best Source for All the News, Reviews and Happenings at Local Businesses throughout California."}
      />
      <GatsbyImage
        image={aboutLocalTierImageData}
        style={{  objectPosition: 'right bottom'}}
        className="h-64 md:h-72 w-full object-cover center object-pos-right-bottom-gatsby"
      />

      <section className="bg-white">
        <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 sm:py-12 lg:px-8">
        <div className="py-4">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-3">
        About LocalTier
        </h1>
        <h2 className="text-lg sm:text-xl md:text-2xl font-display font-normal text-gray-700 mb-3">Your Best Source for All the News, Reviews and Happenings at Local Businesses throughout California</h2>
        <p className="text-gray-500 font-normal mb-6 leading-relaxed">
        When you want to find the best local anything, Local Tier is here to help. It’s our mission to profile highly-skilled professionals  It’s a task we undertake with a great deal of focus and precision, since doing in-depth research, making apples-to-apples comparisons and separating authentic reviews from junk takes someone who is not only deeply involved in local business, but is also devoted to helping local residents find the very best.

        </p>
        <p className="text-gray-500 font-normal mb-6 leading-relaxed">
        That’s why it doesn’t matter if we’re profiling attorneys, plumbers or piano tuners, our process is truly second-to-none.

        </p>

        <h3 className="text-lg md:text-xl text-gray-800 font-semibold mb-3 leading-relaxed">What We Do: </h3>
        <p className="text-gray-500 font-normal mb-6 leading-relaxed">
        At our core, we’re all about highlighting the very best companies, brands and businesses that California has to offer across hundreds of categories. Each month, we help thousands upon thousands of California residents find the very best professional or company to fit their needs. We’ve analyzed hundreds and hundreds of companies and are a definitive source of referrals and revenue for the companies we highlight.


        </p>
        <h3 className="text-lg md:text-xl text-gray-800 font-semibold mb-3  leading-relaxed">How Do You Select the Best Companies?
        </h3>
        <p className="text-gray-500 font-normal mb-6 leading-relaxed">
        We begin by looking at professionals who are active in their given categories across California. Using a collection of specialized tools and resources, we look through publicly available data (reviews, testimonials, ratings, social media, and more) and reach out to the business to verify their hours and other details.</p>


        <p className="text-gray-500 font-normal mb-6 leading-relaxed">
        We ensure that they are in good standing in their local communities and then use a proprietary combination of software to grade them according to the following criteria:
        </p>
        {howItWorksFeatured.map((item, i) => (
                <div key={item.id}>
                  <div className="flex py-2 md:px-4 ">
                    <div className="flex-1">
                      <h4 className="text-base font-semibold text-gray-800 mb-1">
                       - {item.title}
                      </h4>
                      <p className="text-gray-500 pl-3">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <p className="text-gray-500 font-normal mb-6 leading-relaxed mt-3">
              In addition to hand-selecting businesses to feature on Local Tier, we also provide a detailed, rich library of how-to information, tutorials, guides and more written by knowledgeable, experienced professionals. This allows us to connect local communities to industry professionals so that you can make an informed decision with confidence when it comes to choosing the best professional for your business. 

              </p>
        </div>
        </div>

      </section>





    </Layout>
  );
};

export default About;
